import React, { useState } from 'react';
import { Button, Flex, FormBuilder, Heading } from '../../../HoosatUI';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface Session {
  token?: string,
  account?: string,
  method?: string,
  createdAt?: string,
  updtedAt?: string,
  authenticate: (username: string, password: string) => any
}

interface LoginProps {
  session: Session
}

export const Login: React.FC<LoginProps> = ({
  session,
}) => {
  const navigate = useNavigate();
  const [ t ] = useTranslation();
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });
  return (
    <Flex style={{ display: "Flex", width: "100%", justifyContent: "center", alignContent: "center"}}>
      <Heading variant="h1" style={{ marginTop: "4rem"}}>{t("login.header")}</Heading>
      <FormBuilder submitbuttontext={t("login.authenticate-button")} inputs={[
          { 
            itype: "input", 
            id: "email-input", 
            label: "Sähköposti",
            type: "email", 
            placeholder: "Kirjoita sähköposti.",  
            onChange: (e: React.BaseSyntheticEvent) => { setLoginForm({...loginForm, email: e.target.value }); }, 
            value: loginForm.email 
          },
          { 
            itype: "input", 
            id: "password-input", 
            label: "Salasana",
            type: "password", 
            placeholder: "Kirjoita salasana.",  
            onChange: (e: React.BaseSyntheticEvent) => { setLoginForm({...loginForm, password: e.target.value }); }, 
            value: loginForm.password 
          }
        ]}
        style={{ display: "flex", width: "100%", justifyContent: "center", alignContent: "center" }}
        onSubmit={() => { session.authenticate(loginForm.email, loginForm.password) }}
      />
      <Button 
        variant='secondary'
        style={{ marginTop: "15px" }}
        onClick={() => {
        navigate("/admin/register")
      }}>
        {t("login.register-button")}
      </Button>
    </Flex>
  );
}