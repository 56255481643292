import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {  List, ListItem, Image, Icon } from '../../../HoosatUI';

import './Navigation.css'
import { useTranslation } from 'react-i18next';

interface NavigationProps {
}

export const Navigation: React.FC<NavigationProps> = (
) => {
  const [ t ] = useTranslation();
  const navigate = useNavigate();
  return (
    <List className='main-navigation'>
      <ListItem>
        <Link to="/admin/" style={{ textDecoration: "none", color: "inherit" }} title={t("navigation.home")}>
          <Image className="logo" src="https://hoosat.fi/hoosat.svg"  alt="Hoosat Logo"></Image>
        </Link>
      </ListItem>
      {/* <ListItem>
        <Link to="/" style={{ textDecoration: "none", color: "inherit" }} title={t("navigation.front-page")}>
          <Icon className="icon" name="home" />
        </Link>
      </ListItem>
      <ListItem>
        <Link to="/admin/pages" style={{ textDecoration: "none", color: "inherit" }} title={t("navigation.pages")}>
          <Icon className="icon" name="document" />
        </Link>
      </ListItem>
      <ListItem>
        <Link to="/admin/articles" style={{ textDecoration: "none", color: "inherit" }} title={t("navigation.articles")} >
          <Icon className="icon" name="compose" />
        </Link>
      </ListItem>
      <ListItem>
        <Link to="/admin/files" style={{ textDecoration: "none", color: "inherit" }} title={t("navigation.files")} >
          <Icon className="icon" name="computer-laptop" />
        </Link>
      </ListItem> */}
      <ListItem>
        <Link to="/admin/calls" style={{ textDecoration: "none", color: "inherit" }} title={t("navigation.calls")} >
          <Icon className="icon" name="dial-pad" />
        </Link>
      </ListItem>
      <ListItem>
        <Link to="/admin/call-reports" style={{ textDecoration: "none", color: "inherit" }} title={t("navigation.reports")} >
          <Icon className='icon' name="chart" />
        </Link>
      </ListItem>
      <ListItem>
        <Link 
          to="/admin/" 
          style={{ textDecoration: "none", color: "inherit" }} 
          title={t("navigation.logout")}
          onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            e.preventDefault();
            localStorage.removeItem("session");
            navigate("/admin");
            navigate(0);
          }}
          >
          <Icon className="icon" name="close-solid" />
        </Link>
      </ListItem>
    </List>
  )
}