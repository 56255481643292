import React from 'react';
import { Flex, Heading } from '../../../HoosatUI';
import { useTranslation } from 'react-i18next';

interface Session {
  token?: string,
  account?: string,
  method?: string,
  createdAt?: string,
  updtedAt?: string,
  authenticate: (username: string, password: string) => any
}

interface RegisterProps {
  session: Session
}

export const Register: React.FC<RegisterProps> = ({
  session,
}) => {
  const [ t ] = useTranslation();
  return (
    <Flex style={{ display: "Flex", width: "100%", justifyContent: "center", alignContent: "center" }}>
      <Heading style={{ marginTop: "4rem"}}>{t("register.header")}</Heading>
      { session.token }
    </Flex>
  );
}