import React, { useEffect, useState } from "react";
import { Flex, FlexItem, Paragraph } from "../../../../HoosatUI";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { CallData, renderTooltipContent, toPercent } from "./Reports";
import { CallsDTO, SessionDTO } from "../../../@types";
import { useTranslation } from "react-i18next";
import { fetchCallsByGroup } from "../../../Controllers/Calls";

interface GroupChartReportsProps {
  session: SessionDTO;
}

export const GroupChartReports: React.FC<GroupChartReportsProps> = (props: GroupChartReportsProps) => {
  const [ t, ] = useTranslation();
  const [ groupCalls, setGroupCalls ] = useState<CallsDTO[]>([]);
  const [ groupData, setGroupData ] = useState<CallData[] | undefined>(undefined);

  useEffect(() => {
    const getCalls = async () => {
      const groupCalls = await fetchCallsByGroup(props.session);
      console.log(groupCalls);
      setGroupCalls(groupCalls);
    }
    getCalls();
  }, []);
  
  useEffect(() => {
    const newData: CallData[] = [];
    for (const call of groupCalls) {
      let callDate = "";
      if(call.resultsGiven === undefined) {
        callDate = "UNKNOWN";
      } else {
        const dateObj = new Date(call.resultsGiven);
        callDate = `${dateObj.getUTCDate()}-${dateObj.getUTCMonth() + 1}-${dateObj.getUTCFullYear()}`;
      }
      console.log(callDate);
      let pushNew = true;
      for(const data of newData) {
        if(data.date === callDate) {
          pushNew = false;
          break;
        }
      }
      if(pushNew === true) {
        newData.push({
          date: callDate,
          positive: 0,
          negative: 0,
          neutral: 0,
        })
      }
      for (let i = 0; i < newData.length; i++) {
        if (newData[i].date === callDate) {
          if (call.resultType === "POSITIVE") {
            newData[i] = {
              date: newData[i].date,
              positive: newData[i].positive + 1,
              negative: newData[i].negative,
              neutral: newData[i].neutral,
            }
          } else if (call.resultType === "NEGATIVE") {
            newData[i] = {
              date: newData[i].date,
              positive: newData[i].positive,
              negative: newData[i].negative + 1,
              neutral: newData[i].neutral,
            }
          } else if (call.resultType === "NEUTRAL") {
            newData[i] = {
              date: newData[i].date,
              positive: newData[i].positive,
              negative: newData[i].negative,
              neutral: newData[i].neutral + 1,
            }
          } else {
            newData[i] = {
              date: newData[i].date,
              positive: newData[i].positive,
              negative: newData[i].negative,
              neutral: newData[i].neutral + 1,
            }
          }
        }
      }
      console.log(newData);
    }
    setGroupData(newData);
  }, [groupCalls]);

  return (
    <Flex className="call-reports">
      <FlexItem>
        <Paragraph>{t("reports.group-calls")}</Paragraph>
      </FlexItem>
      <FlexItem style={{width: "100%", height: "350px"}}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={500}
            height={400}
            data={groupData}
            stackOffset="expand"
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis tickFormatter={(value: any, _index: number) => { return toPercent(value) }} />
            <Tooltip content={renderTooltipContent} />
            <Area type="monotone" dataKey="positive" stackId="1" stroke="#198754" fill="#198754" />
            <Area type="monotone" dataKey="negative" stackId="1" stroke="#DC3545" fill="#DC3545" />
            <Area type="monotone" dataKey="neutral" stackId="1" stroke="#8B694D" fill="#8B694D" />
          </AreaChart>
        </ResponsiveContainer>
      </FlexItem>
    </Flex>
  );
}