// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-navigation {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.logo {
  width: 1.2rem;
  border-radius: 0px;
  margin-bottom: 0px;
  -webkit-border-radius: 0px;
  box-shadow:none;
}

.icon {
  height: 1.2rem;
  width: 1.2rem;
  fill: #f5f5f5;
}`, "",{"version":3,"sources":["webpack://./src/client/Admin/Components/Navigation/Navigation.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,kBAAkB;EAClB,0BAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,aAAa;AACf","sourcesContent":[".main-navigation {\n  padding-left: 8px !important;\n  padding-right: 8px !important;\n}\n\n.logo {\n  width: 1.2rem;\n  border-radius: 0px;\n  margin-bottom: 0px;\n  -webkit-border-radius: 0px;\n  box-shadow:none;\n}\n\n.icon {\n  height: 1.2rem;\n  width: 1.2rem;\n  fill: #f5f5f5;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
