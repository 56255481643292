import React, { useState } from 'react';
import { SessionDTO } from '../../../@types';
import { useTranslation } from 'react-i18next';

import "./Reports.css";
import { Button, Flex, FlexItem, Heading } from '../../../../HoosatUI';
import { PersonalChartReports } from './PersonalChartReports';
import { GroupChartReports } from './GroupChartReports';

export interface CallData {
  date: string,
  positive: number,
  negative: number,
  neutral: number,
}

export const toPercent = (decimal: number, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`;

export const getPercent = (value: number, total: number) => {
  const ratio = total > 0 ? value / total : 0;

  return toPercent(ratio, 2);
};

export const renderTooltipContent = (o: any) => {
  const { payload, label } = o;
  if(payload === null) {
    return (<></>)
  }
  const total = payload.reduce((result: any, entry: { value: any; }) => result + entry.value, 0);

  return (
    <div className="customized-tooltip-content">
      <p className="total">{`${label} (Total: ${total})`}</p>
      <ul className="list">
        {payload.map((entry: { color: any; name: any; value: any; }, index: any) => (
          <li key={`item-${index}`} style={{ color: entry.color }}>
            {`${entry.name}: ${entry.value} (${getPercent(entry.value, total)})`}
          </li>
        ))}
      </ul>
    </div>
  );
};

interface ReportsProps {
  session: SessionDTO;
}

export const Reports: React.FC<ReportsProps> = ({
  session
}) => {
  const [ t, ] = useTranslation();
  const [ currentReports, setCurrentReports ] = useState<string>("personal");
  const [ currentReportsType, setCurrentReportsType ] = useState<string>("");
 
  return (
    <Flex className="call-reports">
      <FlexItem>
        <Heading variant='h1'>{t("reports.header")}</Heading>
      </FlexItem>
      <FlexItem>
        <Flex className='reports-menu'>
          <FlexItem>
            <Button className="reports-sub-menu-button" onClick={() => { setCurrentReports("personal")}}>{t("reports.personal")}</Button>
          </FlexItem>
          <FlexItem>
            <Button className="reports-sub-menu-button" onClick={() => { setCurrentReports("group")}}>{t("reports.group")}</Button>
          </FlexItem>
        </Flex>
      </FlexItem>
      { (currentReports === "personal") &&
        <FlexItem>
          <Flex className='reports-sub-menu'>
            <FlexItem>
              <Button className="reports-sub-menu-button" variant="secondary" onClick={() => { setCurrentReportsType("calls")}}>{t("reports.personal-calls")}</Button>
            </FlexItem>
            <FlexItem>
              <Button className="reports-sub-menu-button" variant="secondary" onClick={() => { setCurrentReportsType("charts")}}>{t("reports.personal-charts")}</Button>
            </FlexItem>
          </Flex>
          { (currentReportsType === "calls") &&
            <></>
          }
          { (currentReportsType === "charts") &&
            <PersonalChartReports session={session}></PersonalChartReports>
          }
        </FlexItem>
      }
      { (currentReports === "group") &&
        <FlexItem>
          <Flex className='reports-sub-menu'>
            <FlexItem>
              <Button className="reports-sub-menu-button" variant="secondary" onClick={() => { setCurrentReportsType("calls")}}>{t("reports.group-calls")}</Button>
            </FlexItem>
            <FlexItem>
              <Button className="reports-sub-menu-button" variant="secondary" onClick={() => { setCurrentReportsType("charts")}}>{t("reports.group-charts")}</Button>
            </FlexItem>
          </Flex>
          { (currentReportsType === "calls") &&
            <></>
          }
          { (currentReportsType === "charts") &&
          <GroupChartReports session={session}></GroupChartReports>
          }
        </FlexItem>
      }
    </Flex>
  );
} 