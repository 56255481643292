import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactsDTO, SessionDTO } from '../../@types';

import "./Calls.css";
import { Button, Combobox, Flex, FlexItem, Heading, Icon, Input, Textarea } from '../../../HoosatUI';
import { postCall } from '../../Controllers/Calls';

interface CallerProps {
  session: SessionDTO;
  setCurrentComponent:  React.Dispatch<React.SetStateAction<string>>;
  selectedContact: ContactsDTO;
}



export const Caller: React.FC<CallerProps> = (props: CallerProps) => {
  const [ t, ] = useTranslation();

  const statusToTranslation: Record<string, string> = {
    mildInterest: t("caller.results.mild-interest"),
    interestShown: t("caller.results.interest-shown"),
    moreTimeRequested: t("caller.results.more-time-requested"),
    moreInfoOrDemoRequested: t("caller.results.more-info-or-demo-requested"),
    callBackRequested: t("caller.results.call-back-requested"),
    meetingScheduled: t("caller.results.meeting-scheduled"),
    existingCustomerNeedsDiscussed: t("caller.results.customer-needs-discussed"),
    existingCustomerFeedbackDiscussed: t("caller.results.customer-feedback-discussed"),
    existingCustomerUpsellOpportunities: t("caller.results.customer-upsell-opportunities"),
    offerDeclinedPolitely: t("caller.results.offer-declined-politely"),
    removeFromCallingListRequested: t("caller.results.requested-removal-from-calling-list"),
    usingCompetitorsProduct: t("caller.results.competitor-product"),
    prospectUnavailable: t("caller.results.unavailable"),
    prospectBusy: t("caller.results.busy"),
    callLaterRequested: t("caller.results.requested-call-later"),
    alternativeContactRequested: t("caller.results.requested-alternative-contact-method"),
    leftVoicemail: t("caller.results.left-voicemail"),
    gatekeeperToDecisionMaker: t("caller.results.gatekeeper-to-decision-maker"),
    callDroppedTechnicalIssues: t("caller.results.call-dropped-techical-issues"),
  };
  /**
    Prospect shows mild interest
    Prospect shows interest
    Prospect requested more time
    Propsect requested more information or demo by email.
    Prospect requested call back at a specific future date.
    Prospect agrees to a schedule a meeting
    Existing customer, discussed their needs.
    Existing customer, discussed feedback.
    Existing customer, discussed potential upsell opportunities
    Prospect declines our offer politely
    Prospect requested removal from our calling list
    Prospect told they are using competitor's product or service.
    Prospect was unavailable
    Prospect was busy
    Prospect requested to call later
    Prospect requested alternative contact method
    Left voicemail 
    Gatekeeper connected to decision-maker
    Call dropped due to technical issues.
    */

  // @ts-ignore
  const getStatusWithTranslation = (value: string): string | null => {
    for (const key in statusToTranslation) {
      if (statusToTranslation.hasOwnProperty(key) && statusToTranslation[key] === value) {
        return key;
      }
    }
    return null;
  }

  const getStatusTranslationsAsArray = (): string[] => {
    return Object.values(statusToTranslation);
  } 

  const [connected, setConnected] = useState(false);
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [note, setNote] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [startTime, setStartTime] = useState<Date | null>(null);

  useEffect(() => {
    if (!ws && !connected) {
      const ws = new WebSocket("wss://crm.hoosat.fi:42424")
      setWs(ws);
      ws.onopen = () => {
        setConnected(true);
        if (ws) {
          const syncId = props.session.account;
          ws.send(`register: ${syncId}`);
        }
      }
      
      ws.onmessage = (message: MessageEvent) => {
        console.log(message);
      }

      ws.onclose = (event: CloseEvent) => {
        setConnected(false);
        console.log('WebSocket closed: ', event);
      };

      ws.onerror = (error: Event) => {
        setConnected(false);
        console.error('WebSocket error:', error);
      };
    }
  }, [connected])
  
  const sendPhoneNumber = () => {
    if (ws && connected) {
      ws.send(props.selectedContact.phone);
      setStartTime(new Date());
      ws.close();
      setWs(null);
      setConnected(false);
    }
  }

  const noteChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  }

  const resultsChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(e.target.value);
  }

  // @ts-ignore
  const findMostCommonValue = (array: string[]): string | null => {
    const counts: Record<string, number> = {};
    array.forEach(value => {
      if (counts[value]) {
        counts[value]++;
      } else {
        counts[value] = 1;
      }
    });
    let mostCommonValue: string | null = null;
    let highestCount = 0;
    for (const value in counts) {
      if (counts[value] > highestCount) {
        highestCount = counts[value];
        mostCommonValue = value;
      }
    }
  
    return mostCommonValue;
  };

  const submitClick = async () => {
    const statusCode = getStatusWithTranslation(status);
    let statusType: string = "";
    switch (statusCode) {
      // Positive Statuses
      case "mildInterest":
      case "interestShown":
      case "moreTimeRequested":
      case "moreInfoOrDemoRequested":
      case "callBackRequested":
      case "meetingScheduled":
      case "existingCustomerNeedsDiscussed":
      case "existingCustomerFeedbackDiscussed":
      case "existingCustomerUpsellOpportunities":
        statusType = "POSITIVE";
        break;
      // Negative Statuses
      case "offerDeclinedPolitely":
      case "removeFromCallingListRequested":
      case "usingCompetitorsProduct":
        statusType = "NEGATIVE";
        break;
      // Neutral Statuses
      case "prospectUnavailable":
      case "prospectBusy":
      case "leftVoicemail":
      case "callLaterRequested":
      case "alternativeContactRequested":
      case "gatekeeperToDecisionMaker":
        statusType = "NEUTRAL";
        break;
      default:
        statusType = "UNKNOWN";
        break;
    }
    const result = await postCall(props.session, props.selectedContact._id, statusCode!, statusType!, startTime?.toString()!, (new Date()).toString(), note);
    console.log(`post call result: ${result}`);
    props.setCurrentComponent("calls.contacts-list");
  }

  return (
    <Flex className='caller'>
      <FlexItem>
        <Heading variant="h2">{t("caller.calling-heading")}</Heading>
      </FlexItem>
      <FlexItem>
        <Input label={t("caller.contact.name")} value={props.selectedContact.name} disabled />
      </FlexItem>
      <FlexItem>
        <Input label={t("caller.contact.ycode")} value={props.selectedContact.ycode} disabled />
      </FlexItem>
      <FlexItem>
        <Button onClick={sendPhoneNumber} title={t("caller.sync-call.tooltip")}><Icon className="icon" name="phone" />{t("caller.sync-call.button")}</Button>
      </FlexItem>
      <FlexItem>
        <Input label={t("caller.contact.phone")} value={props.selectedContact.phone} />
      </FlexItem>
      <FlexItem>
        <Input label={t("caller.contact.web")} value={props.selectedContact.web} />
      </FlexItem>
      <FlexItem>
        <Input label={t("caller.contact.email")} value={props.selectedContact.email} />
      </FlexItem>
      <FlexItem>
        <Heading variant="h2">{t("caller.results-heading")}</Heading>
      </FlexItem>
      <FlexItem>
        <Textarea label={t("caller.result.notes")} onChange={noteChanged} value={note}>
        </Textarea>
      </FlexItem>
      <FlexItem>
        <Combobox label={t("caller.result.result")} onSelect={resultsChanged} className='caller-results' multiple={false} options={getStatusTranslationsAsArray()} value={status}></Combobox>
      </FlexItem>
      <FlexItem>
        <Button className="caller-submit-button" onClick={submitClick}>{t("caller.submit-button")}</Button>
      </FlexItem>
    </Flex>
  );
} 