import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactsDTO, SessionDTO } from '../../@types';
import { Button, Heading, List, ListItem, PageBuilder } from '../../../HoosatUI';

import "./Calls.css";
import { ListContacts } from './ListContacts';
import { Caller } from './Caller';
import { Reports } from './Reports/Reports';

interface CallsProps {
  session: SessionDTO;
}

export const Calls: React.FC<CallsProps> = ({
  session
}) => {
  const [ t, ] = useTranslation();
  const [ selectedContact, setSelectedContact ] = useState<ContactsDTO>({} as ContactsDTO);
  const [ currentComponent, setCurrentComponent ] = useState<string>("calls.contacts-list");
  
  return (
    (currentComponent === "calls.contacts-list")
    ? <ListContacts session={session} setCurrentComponent={setCurrentComponent} setSelectedContact={setSelectedContact} selectedContact={selectedContact}></ListContacts>
    : (currentComponent === "calls.caller")
    ? <Caller session={session} setCurrentComponent={setCurrentComponent} selectedContact={selectedContact}></Caller>
    : <></>
  );
} 