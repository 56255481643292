import { SessionDTO, ContactsWithLastCallResultDTO } from "../@types";

export const postCall = async (
  session: SessionDTO, 
  contact: string,
  result: string,
  resultType: string,
  callInitiated: string,
  resultsGiven: string,
  note: string,
) => {
  if(session.token === undefined) {
    if(process.env.NODE_ENV === "development") console.log("session.token was undefined, can not continue creating page.");
    return [] as ContactsWithLastCallResultDTO[];
  }
  try {
    const uri = encodeURI(`/api/calls/`);
    const fetched = await fetch(uri, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": session.token!
      },
      body: JSON.stringify({
        contact: contact,
        caller: session.account,
        result: result,
        resultType: resultType,
        callInitiated: callInitiated,
        resultsGiven: resultsGiven,
        note: note
      })
    });
    const response = await fetched.json();
    return response;
  } catch (error) {
    console.log(error);
  }
}

export const fetchCallsByCaller = async (
  session: SessionDTO
) => {
  if(session.token === undefined) {
    if(process.env.NODE_ENV === "development") console.log("session.token was undefined, can not continue creating page.");
    return [] as ContactsWithLastCallResultDTO[];
  }
  try {
    const uri = encodeURI(`/api/calls/caller`);
    const fetched = await fetch(uri, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": session.token!
      }
    });
    const response = await fetched.json();
    return response.calls;
  } catch (error) {
    console.log(error);
  }
}

export const fetchCallsByGroup = async (
  session: SessionDTO
) => {
  if(session.token === undefined) {
    if(process.env.NODE_ENV === "development") console.log("session.token was undefined, can not continue creating page.");
    return [] as ContactsWithLastCallResultDTO[];
  }
  try {
    const uri = encodeURI(`/api/calls/group`);
    const fetched = await fetch(uri, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": session.token!
      }
    });
    const response = await fetched.json();
    return response.calls;
  } catch (error) {
    console.log(error);
  }
}