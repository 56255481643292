import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Admin } from './Admin/Routes/Admin';

import "./App.css";

/**
 * Represents the main component of the client-side React application.
 * It defines the routes for the app using the Routes and Route components from react-router-dom.
 * The Home component is rendered when the URL path is "/" and the About component is rendered when
 * the URL path is "/about".
 *
 * @returns {JSX.Element} The React component that defines the app routes.
 */

export default function App(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<Admin />} />
      { /* Do not modify these following routes, they are for the cms admin. */}
      <Route path="/admin" element={<Admin />}/>
      <Route path="/admin/:page" element={<Admin />} />
    </Routes>
  );
}
