// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.call-reports {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.call-reports > .FlexItem {
  display: flex;
  width: 100%;
}

.reports-menu {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 15px;
  margin-bottom: 15px;
}

.reports-menu > .FlexItem {
  width: auto !important;
}

.reports-sub-menu {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 15px;
}

.reports-sub-menu-button {
  width: 10rem;
}`, "",{"version":3,"sources":["webpack://./src/client/Admin/Routes/Calls/Reports/Reports.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,YAAY;AACd","sourcesContent":[".call-reports {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.call-reports > .FlexItem {\n  display: flex;\n  width: 100%;\n}\n\n.reports-menu {\n  display: flex;\n  width: 100%;\n  flex-direction: row;\n  gap: 15px;\n  margin-bottom: 15px;\n}\n\n.reports-menu > .FlexItem {\n  width: auto !important;\n}\n\n.reports-sub-menu {\n  display: flex;\n  width: 100%;\n  flex-direction: row;\n  gap: 15px;\n}\n\n.reports-sub-menu-button {\n  width: 10rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
