
import { SessionDTO, ContactsWithLastCallResultDTO } from "../@types";

export const fetchContacts = async (
  session: SessionDTO, 
  search: string, 
  skip: number, 
  limit: number
): Promise<ContactsWithLastCallResultDTO[]> => {
  if (search === "") {
    search = "*";
  }
  try {
    const uri = encodeURI(`/api/contacts/${search}/${skip.toString()}/${limit.toString()}`);
    console.log(uri);
    const result = await fetch(uri, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": session.token!
      }
    });
    const response = await result.json();
    if(response.contacts !== undefined) {
      return response.contacts;
    }
  } catch (error) {
    console.log(error);
  }
  return [] as ContactsWithLastCallResultDTO[];
}

export const countContacts = async (session: SessionDTO): Promise<number> => {
  if(session.token === undefined) {
    if(process.env.NODE_ENV === "development") console.log("session.token was undefined, can not continue creating page.");
    return 0;
  }
  try {
    const uri = encodeURI(`/api/contacts/count`);
    console.log(uri);
    const result = await fetch(uri, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": session.token!
      }
    });
    const response = await result.json();
    console.log(response);
    if(response.count !== undefined) {
      return parseFloat(response.count);
    }
  } catch (error) {
    console.log(error);
  }
  return 0;
}